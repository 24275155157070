import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES, SHOP_MODE_ROUTES } from 'app/constants';
import { useShopStore } from 'app/stores';

export const HomePage = () => {
  const { shop } = useShopStore();

  const navigateTo = useMemo(() => {
    if (shop?.shopId) {
      return SHOP_MODE_ROUTES(shop.shopId).EVENTS_LIST;
    }
    return ROUTES.EVENTS_LIST;
  }, [shop?.shopId]);

  return <Navigate to={navigateTo} replace />;
};
