import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { z } from 'zod';

import { EXCEL_SIZE } from 'app/configs';
import { FormPreviewItem } from 'app/models/Common';
import {
  MarriageStatusType, MemberLevelType, RankCodeType, UserResponse, UserType, UserTypeEnum,
} from 'app/models/User';
import { phoneValidation } from 'app/utils';

dayjs.extend(isSameOrBefore);
export const USER_FORM_SCHEMA = z
  .object({
    passwordInputNeeded: z
      .boolean(),
    email: z
      .string()
      .min(1, '必填')
      .regex(/^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i, '必須是有效的電子郵件')
      .max(200, { message: '不可多於200個字元' }),
    contactEmail: z
      .string()
      .max(200, { message: '不可多於200個字元' }),
    displayName: z
      .string({ required_error: '必填' })
      .min(1, '必填')
      .max(200, { message: '不可多於200個字元' }),
    chineseName: z
      .string()
      .regex(/^[\u4e00-\u9fa5 ·]*$/, { message: '只可以輸入空白和中文字母' })
      .min(2, { message: '最少要2個字元' })
      .max(50, '不可多於50個字元')
      .or(z.literal('')),
    englishName: z.string()
      .regex(/^[a-zA-Z ']+$/, { message: '英文名字格式錯誤' })
      .max(200, '不可多於200個字元')
      .or(z.literal('')),
    firstName: z
      .string()
      .max(200, { message: '不可多於200個字元' }),
    lastName: z
      .string()
      .max(200, { message: '不可多於200個字元' }),
    agentLicenseNumber: z
      .string()
      .max(200, { message: '不可多於200個字元' }),
    countryCallingCode: z
      .string()
      .min(1, '必選'),
    contactPhone: z
      .string()
      .or(z.literal('')),
    jobTitle: z
      .string()
      .max(200, { message: '不可多於200個字元' }),
    userType: z
      .custom<UserType>(),
    memberLevel: z
      .custom<MemberLevelType>()
      .or(z.literal('')),
    isReceivedInformation: z
      .boolean(),
    quote: z
      .string()
      .max(255, { message: '超出字數上限' }),
    password: z
      .string()
      .max(200, { message: '不可多於200個字元' }),
    confirmPassword: z
      .string(),
    branchCode: z
      .string()
      .max(50, { message: '不可多於50個字元' }),
    shopId: z
      .number()
      .nullable(),
    departmentId: z
      .number()
      .nullable(),
    family: z
      .string()
      .max(100, { message: '不可多於100個字元' }),
    cardAddress: z
      .string()
      .max(500, { message: '不可多於500個字元' }),
    marriageStatus: z
      .custom<MarriageStatusType>()
      .optional(),
    rankCode: z
      .custom<RankCodeType>()
      .or(z.literal('')),
    rankCodeNumber: z
      .string()
      .max(10, { message: '不可多於10個字元' }),
    branchName: z
      .string()
      .max(200, { message: '不可多於200個字元' }),
    entryDate: z
      .date()
      .refine(
        (date) => dayjs(date).isSameOrBefore(dayjs(), 'day') && dayjs(date).isAfter(dayjs('1900-01-01'), 'day'),
        { message: '日期不正確' },
      )
      .optional(),
    birthDate: z
      .date()
      .refine(
        (date) => dayjs(date).isSameOrBefore(dayjs(), 'day') && dayjs(date).isAfter(dayjs('1900-01-01'), 'day'),
        { message: '日期不正確' },
      )
      .optional(),
    avatar: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
      )
      .optional(),
  })
  .refine(({ countryCallingCode, contactPhone }) => phoneValidation(countryCallingCode, contactPhone), { message: '請輸入正確的電話號碼', path: ['contactPhone'] })
  .refine(({ password, passwordInputNeeded }) => !passwordInputNeeded || password, { message: '必填', path: ['password'] })
  .refine(({ confirmPassword, passwordInputNeeded }) => !passwordInputNeeded || confirmPassword, { message: '必填', path: ['confirmPassword'] })
  .refine(({ userType, agentLicenseNumber }) => userType === UserTypeEnum.公眾 || agentLicenseNumber, {
    path: ['agentLicenseNumber'],
    message: '必填',
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: '密碼不一致',
    path: ['confirmPassword'],
  });
export type UserFormDataType = z.infer<typeof USER_FORM_SCHEMA>;

export enum UserDetailTab {
  會員資料 = 0,
  準成員列表 = 1,
  積分紀錄 = 2,
  登入記錄 = 3,
}

export const ADD_POINT_FORM_SCHEMA = z
  .object({
    point: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填')
      .refine((value) => !Number.isNaN(value), { message: '請輸入數值' }),
    remarks: z.string(),
  })
  .required();

export type AddPointFormType = z.infer<typeof ADD_POINT_FORM_SCHEMA>;
export const USER_POINT_HISTORY_PAGE_SIZE = 10;

export const USER_TAG_FORM_SCHEMA = z
  .object({
    tagName: z.string().min(1, '必填').min(2, '最少要2個字元').max(100, '不可多於100個字元'),
    users: z.array(z.custom<UserResponse>()),
  });

export type UserTagFormType = z.infer<typeof USER_TAG_FORM_SCHEMA>;

export const IMPORT_USER_TO_USER_TAG_EXCEL_SCHEMA = z
  .object({
    tagName: z
      .string()
      .min(1, '必選'),
    file: z
      .array(
        z.instanceof(File),
        { required_error: '必填' },
      )
      .refine((files) => files[0].size <= EXCEL_SIZE, 'EXCEL檔案不可大於10MB')
      .refine((files) => files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      || files[0].type === 'application/vnd.ms-excel', '只接受Excel檔案'),
  });

export type ImportUserToUserTagExcelDataType = z.infer<typeof IMPORT_USER_TO_USER_TAG_EXCEL_SCHEMA>;
