import { Disclosure } from '@headlessui/react';
import { ReactNode } from 'react';
import { TbChevronRight } from 'react-icons/tb';

import { MenuParentItem } from 'app/constants/Menu';
import cn from 'app/utils/ClassName';

const NAV_FONT_STYLE_CLASS = 'flex w-full justify-between bg-bg-transparent py-3 text-left text-[14px] font-normal transition-colors duration-300';
const NAV_HOVER_FONT_STYLE_CLASS = 'hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75';

interface Props {
  isSideBarOpen: boolean;
  isOpen: boolean;
  menu: MenuParentItem;
  children: ReactNode;
  defaultOpen?: boolean;
  parentId?: string;
}

export const SideBarDisclosure = ({
  isSideBarOpen, isOpen, menu, children, defaultOpen, parentId,
}: Props) => {
  const hideOtherDisclosuresHandle = (id: string) => {
    if (document.querySelectorAll('button[data-headlessui-state="open"]')) {
      const buttons = Array.from(
        document.querySelectorAll('button[data-headlessui-state="open"]'),
      ) as HTMLButtonElement[];
      buttons.forEach((button) => {
        if (!(button?.id === id || button?.id === parentId)) {
          button?.click();
        }
      });
    }
  };

  return (
    <Disclosure
      key={menu.key}
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            id={menu.key}
            onClick={() => hideOtherDisclosuresHandle(menu.key)}
            className={cn(
              NAV_FONT_STYLE_CLASS,
              NAV_HOVER_FONT_STYLE_CLASS,
              `${
                isOpen
                  ? 'text-brand-primary'
                  : 'text-text-secondary'
              }`,
            )}
          >
            <div className="flex items-center">
              {menu.icon}
              {isSideBarOpen && (
              <div className="ml-2">{menu.title}</div>
              )}
            </div>
            {isSideBarOpen && (
            <TbChevronRight
              className={`${open ? 'rotate-90' : ''} size-5`}
            />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="flex flex-col pl-[28px] text-[14px] font-normal text-text-secondary">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
