import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { APP_NAME } from 'app/configs';
import { ScheduledNotification } from 'app/models/Notification';

export type NotificationStore = {
  notification: ScheduledNotification | null
  setNotification: (notification: ScheduledNotification) => void
  resetNotification: () => void
};

export const useNotificationStore = create<NotificationStore>()(
  persist(
    (set) => ({
      notification: null,
      setNotification: (notification) => set(() => ({
        notification,
      })),
      resetNotification: () => set(() => ({
        notification: null,
      })),
    }),
    {
      name: `${APP_NAME}-drive-storage`,
    },
  ),
);
