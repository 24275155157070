import { RowSelectionState } from '@tanstack/react-table';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getInstantEventList, GetInstantEventListParams } from 'app/apis/InstantEventServices';
import { GetShopListParams, getShopList, getOneShop } from 'app/apis/ShopServices';
import {
  Container, Table, TableFilterRow, TableFooter,
} from 'app/components';
import { ToolBar } from 'app/components/Page/ToolBar';
import { useAutoComplete, useTableData } from 'app/hooks';
import { FilterTypeOption } from 'app/models/Common';
import { INSTANT_EVENT_LIST_SORT_OPTION_MAP, InstantEventDeletedEnum, InstantEventResponse } from 'app/models/InstantEvent';
import { ShopResponse } from 'app/models/Shop';

import { InstantEventListColumns } from './InstantEventListColumns';

export const InstantEventListPage = () => {
  const [searchParams] = useSearchParams();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const {
    dataList: data,
    count,
    loading,
    page,
  } = useTableData<InstantEventResponse, GetInstantEventListParams>({
    call: getInstantEventList,
    filterVariableArray: ['asc', 'desc', 'name', 'shopId', 'startDateFrom', 'startDateTo', 'deleted'],
  });

  const {
    setSearchText: setShopText,
    dataList: shopList,
    onMenuScrollToBottom: onShopMenuScrollToBottom,
  } = useAutoComplete<GetShopListParams, ShopResponse>({
    getListCall: getShopList,
    getItemCall: (shopId) => getOneShop(Number(shopId)),
    selectedId: searchParams.get('shopId') ?? undefined,
    labelRender: (featuredShop: ShopResponse) => ({
      label: `#${featuredShop.shopId} | ${featuredShop.shopTitle}`,
      value: featuredShop.shopId,
    }),
    searchTextFieldName: 'shopTitle',
  });

  return (
    <Container>
      <ToolBar
        title="即時活動列表"
      />
      <TableFilterRow
        filterItems={[
          {
            type: FilterTypeOption.sort,
            label: '排序 ASC',
            name: 'asc',
            optionMap: INSTANT_EVENT_LIST_SORT_OPTION_MAP,
          },
          {
            type: FilterTypeOption.sort,
            label: '排序 DESC',
            name: 'desc',
            optionMap: INSTANT_EVENT_LIST_SORT_OPTION_MAP,
          },
          {
            type: FilterTypeOption.search,
            label: '標題',
            name: 'name',
            placeholder: '捜尋標題',
          },
          {
            type: FilterTypeOption.autocomplete,
            label: '商店',
            name: 'shopId',
            placeholder: '捜尋商店',
            options: shopList,
            onMenuScrollToBottom: onShopMenuScrollToBottom,
            onInputChange: setShopText,
          },
          {
            type: FilterTypeOption.date,
            label: '開始日期',
            name: { start: 'startDateFrom', end: 'startDateTo' },
            placeholder: '捜尋開始日期',
          },
          {
            type: FilterTypeOption.select,
            label: '狀態',
            name: 'deleted',
            placeholder: '現存',
            options: Object.entries(InstantEventDeletedEnum).map(([key, value]) => ({
              label: value,
              value: key,
            })),
          },
        ]}
      />
      <Table
        data={data}
        defaultColumns={InstantEventListColumns()}
        rowSelection={rowSelection}
        loading={loading}
        setRowSelection={setRowSelection}
      />
      <TableFooter count={count} page={page} />
    </Container>
  );
};
