import { Filter, ListResult } from 'app/models/Common';
import { InstantEventDeletedType, InstantEventResponse } from 'app/models/InstantEvent';

import Axios from '.';

export interface GetInstantEventListParams extends Filter<InstantEventResponse> {
  name?: string;
  shopId?: number;
  startDateFrom?: string;
  startDateTo?: string;
  deleted?: InstantEventDeletedType;
}
/**
 * * List All Instant Event, Login Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/instantEvent)
 */

export const getInstantEventList = async (params: GetInstantEventListParams) => {
  const { data } = await Axios.get<ListResult<InstantEventResponse>>('/commerces/instantEvent', { params });
  return data;
};
