import { Filter, ListResult } from 'app/models/Common';
import {
  MemberLevelType, User, UserResponse, UserType, UserTag,
  RankCodeType,
  UserMemberPointRecordResponse,
  UserPreAgentResponse,
  UserSessionResponse,
  UserInvitationResponse,
  UserResetPasswordLinkResponse,
} from 'app/models/User';

import Axios from './index';

export interface GetUserListParams extends Filter<User> {
  displayName?: string;
  name?: string;
  email?: string;
  jobTitle?: string;
  agentLicenseNumber?: string;
  userType?: UserType;
  memberLevel?: MemberLevelType;
  departmentIds?: number[];
  branchCodes?: string[];
  displayedMemberId?: number | string;
  contactPhonePrefix?: string;
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<UserResponse>>('/users', {
    params,
    paramsSerializer: {
      indexes: false,
    },
  });
  return data;
};

export interface GetUserTagListParams extends Filter<UserTag> {
  tagName?: string;
}

/**
* * List all tags, Admin Only (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/users/tags)
+*/

export const getUserTagList = async (params: GetUserTagListParams) => {
  const { data } = await Axios.get('/users/tags', { params });
  return data;
};

/**
 * * Get One User By userId, Admin Only (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/users/-userId-)
 */

export const getOneUser = async (
  userId: number,
) => {
  const { data } = await Axios.get<UserResponse>(`/users/${userId}`);
  return data;
};

/**
 * *Create One user, Admin Only (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/users)
 */

export interface CreateOneUserParams {
  email?: string | null;
  displayName?: string | null;
  chineseName?: string | null;
  englishName?: string | null;
  isReceivedInformation?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: string | null;
  agentLicenseNumber?: string | null;
  countryCallingCode?: string | null;
  contactPhone?: string | null;
  memberLevel?: MemberLevelType | null;
  userType: UserType;
  jobTitle?: string | null;
  quote?: string | null;
  password: string;
  isStaff?: boolean;
  rankCode?: RankCodeType | null;
  rankCodeNumber?: string | null;
  branchCode?: string | null;
  branchName?: string | null;
  entryDate?: string | null;
  birthDate?: string | null;
  departmentId?: number | null;
  family?: string | null;
  cardAddress?: string | null;
}

export const createOneUser = async (params: CreateOneUserParams) => {
  const { data } = await Axios.post<UserResponse>('/users', params);
  return data;
};

/**
* * Update One User By userId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#patch-/v2/users/-userId-)
+*/

export const updateOneUser = async (
  userId: number | string,
  params: Partial<CreateOneUserParams>,
) => {
  const { data } = await Axios.patch<UserResponse>(`/users/${userId}`, params);
  return data;
};

/**
 * * Delete One user, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#delete-/v2/users/-userId-)
 */

export const deleteOneUser = async (userId: number | string) => {
  await Axios.delete(`/users/${userId}`);
};
/**
 * * Activate One User By userId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/users/-userId-/activate)
 */

export const activateOneUser = async (userId: number | string) => {
  await Axios.post(`/users/${userId}/activate`);
};
/**
 * * Deactivate One User By userId, Admin Only (hhttps://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/users/-userId-/deactivate)
 */

export const deactivateOneUser = async (userId: number | string) => {
  await Axios.post(`/users/${userId}/deactivate`);
};

/**
* * get all membership point record (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#get-/v2/membership/point)
+*/
export interface GetUserMembershipPointListParams
  extends Filter<UserMemberPointRecordResponse> {
  userId?: number;
  courseId?: number;
  eventId?: number;
}

export const getUserMembershipPointList = async (
  params: GetUserMembershipPointListParams,
) => {
  const { data } = await Axios.get<ListResult<UserMemberPointRecordResponse>>(
    '/membership/point',
    { params },
  );
  return data;
};

/**
* * Create One Membership Point Record, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/membership/point)
+*/

export interface UpdateOneUserMembershipPointParams {
  userId: number | string;
  point: number;
  remarks?: string;
}

export const updateOneUserMembershipPoint = async (
  params: UpdateOneUserMembershipPointParams,
) => {
  const { data } = await Axios.post<any>('/membership/point', params);
  return data;
};

/**
* * List All Pre Agent, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#get-/v2/preAgents)
+*/
export interface GetUserPreAgentListParams
  extends Filter<UserPreAgentResponse> {
  userId?: number;
  studentNumber?: string;
}

export const getUserPreAgentList = async (
  params: GetUserPreAgentListParams,
) => {
  const { data } = await Axios.get<ListResult<UserPreAgentResponse>>(
    '/preAgents',
    { params },
  );
  return data;
};

/**
* * Get One Pre Agent By PreAgentId, Login Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#get-/v2/preAgents/-preAgentId-)
+*/

export const getOneUserPreAgent = async (
  preAgentId: number,
) => {
  const { data } = await Axios.get<UserPreAgentResponse>(
    `/preAgents/${preAgentId}`,
  );
  return data;
};

/**
* * Delete One Pre Agent By PreAgentId, Login Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#delete-/v2/preAgents/-preAgentId-)
+*/

export const deleteUserPreAgent = async (preAgentId: number) => {
  await Axios.delete(`/preAgents/${preAgentId}`);
};

/**
* * Get All User Session By userId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#get-/v2/preAgents)
+*/

export const getAllUserSession = async (
  userId: number,
) => {
  const { data } = await Axios.get<UserSessionResponse[]>(`/users/${userId}/sessions`);
  return data;
};

/**
* * Revoke User Session By userId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#delete-/v2/users/-userId-/sessions)
+*/

export const revokeUserSession = async (
  userId: number,
  sessionKey: string,
) => {
  await Axios.delete(`/users/${userId}/sessions`, { params: { sessionKey } });
};

export interface CreateInvitationParams extends Partial<Omit<CreateOneUserParams, 'password'>> {}

/**
 * * Create Invitation with user info prefilled, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/users/sendInvitationLink)
 */

export const createUserInvitation = async (params: CreateInvitationParams) => {
  const { data } = await Axios.post<UserInvitationResponse>('/users/sendInvitationLink', params);
  return data;
};

/**
 * * Create Reset Password Link with user info prefilled, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/users/-userId-/sendResetPasswordLink)
 */

export const createResetPasswordLink = async (userId: number) => {
  const { data } = await Axios.post<UserResetPasswordLinkResponse>(`/users/${userId}/sendResetPasswordLink`);
  return data;
};

export interface CreateOneTagsParams {
  tagName: string;
  userIds: number[];
}

/**
 * * Create One tags, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/users/tags)
 */

export const createOneUserTag = async (params: CreateOneTagsParams) => {
  const { data } = await Axios.post<UserTag>('/users/tags', params);
  return data;
};

export interface GetUserListByTagNameParams extends Filter<User> {
  tagName: string;
}

/**
 * * list users By tag name, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#get-/v2/users/tags/-tagName-/users)
 */

export const getUserListByTagName = async ({ tagName, ...params }: GetUserListByTagNameParams) => {
  const { data } = await Axios.get<ListResult<UserResponse>>(`/users/tags/${tagName}/users`, { params });
  return data;
};

/**
 * * Delete One Tag, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#delete-/v2/users/tags/-tagName-)
 */

export const deleteOneUserTag = async (tagName: string) => {
  await Axios.delete(`/users/tags/${tagName}`);
};

export interface UpdateOneTagsParams {
  tagName: string;
}

/**
 * * Update One tags, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#patch-/v2/users/tags/-tagName-)
 */

export const updateOneUserTag = async (tagName: string, params: UpdateOneTagsParams) => {
  const { data } = await Axios.patch<UserTag>(`/users/tags/${tagName}`, params);
  return data;
};

/**
 * * Import Or Update User From Excel File, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/users/import)
 */

export const importOrUpdateUserFromExcel = async (params: FormData) => {
  const { data } = await Axios.post('/users/import', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

/**
 * * Import Users To One tags, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#post-/v2/users/tags/import)
 */

export const importUsersToUserTag = async (params: FormData) => {
  const { data } = await Axios.post('/users/tags/import', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

/**
 * * Delete One UserTag By UserId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/doc/v2#delete-/v2/users/tags/-tagName-/-userId-)
 */

export const deleteOneUserToUserTag = async (tagName: string, userId: number) => {
  await Axios.delete(`/users/tags/${tagName}/${userId}`);
};
