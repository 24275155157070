import 'rc-drawer/assets/index.css';
import 'app/assets/scss/drawer.scss';
import Drawer from 'rc-drawer';
import { useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Logo from 'app/assets/images/logo.png';
import { ROUTES, SIDE_BAR_WIDTH } from 'app/constants';
import { MenuItem, MenuParentItem, MENUS } from 'app/constants/Menu';
import { SHOP_MENUS } from 'app/constants/ShopMenu';
import {
  useAuthStore, useCommerceItemStore, useLayoutStore, useShopStore,
} from 'app/stores';
import cn from 'app/utils/ClassName';

import { Image } from '../Image';

import { SideBarDisclosure } from './SideBarDisclosure';

const NAV_FONT_STYLE_CLASS = 'flex w-full justify-between bg-bg-transparent py-3 text-left text-[14px] font-normal transition-colors duration-300';
const NAV_HOVER_FONT_STYLE_CLASS = 'hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75';

export const MenuDrawer = ({ currentPath }: { currentPath: string }) => {
  const { isDrawerOpen, closeDrawer } = useLayoutStore();
  const { profile } = useAuthStore();
  const { shop } = useShopStore();

  const {
    newsSubmittedCount,
    productSubmittedCount,
    eventSubmittedCount,
    courseSubmittedCount,
    newsModifyRequestCount,
    productModifyRequestCount,
    eventModifyRequestCount,
    courseModifyRequestCount,
  } = useCommerceItemStore();

  // eslint-disable-next-line react/no-unused-prop-types
  const MenuDisplay = useCallback(({ menu, parentId }: { menu: MenuParentItem | MenuItem | Omit<MenuItem, 'icon'>, parentId?: string }) => {
    if ('children' in menu) {
      return (
        <SideBarDisclosure
          key={menu.key}
          isSideBarOpen
          menu={menu}
          defaultOpen={currentPath === menu.key || menu.children.some((child) => currentPath === child.key)}
          isOpen={currentPath === menu.key || menu.children.some((child) => currentPath === child.key)}
          parentId={parentId}
        >
          {menu.children
            .filter((item) => !('permissions' in item)
            || (item.permissions?.length
            && item.permissions.some((permissions) => permissions === profile?.role))).map((child) => (
              <MenuDisplay key={child.key} menu={child} parentId={menu.key} />
            ))}
        </SideBarDisclosure>
      );
    }

    return (
      <NavLink
        key={menu.key}
        to={menu.href}
        className={({ isActive }) => cn(
          NAV_FONT_STYLE_CLASS,
          NAV_HOVER_FONT_STYLE_CLASS,
          'flex items-center justify-start gap-[8px]',
          `${
            isActive
              ? 'text-brand-primary'
              : 'text-text-secondary'
          }`,
        )}
      >
        {'icon' in menu ? menu.icon : null}
        <div className="flex-1">{menu.title}</div>
        <div className="flex items-center gap-[8px]">
          {menu.key === 'news' && newsModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {newsModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'product' && productModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {productModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'events' && eventModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {eventModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'course' && courseModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {courseModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'news' && newsSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {newsSubmittedCount}
              </div>
            )
            : null}
          {menu.key === 'product' && productSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {productSubmittedCount}
              </div>
            )
            : null}
          {menu.key === 'events' && eventSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {eventSubmittedCount}
              </div>
            )
            : null}
          {menu.key === 'course' && courseSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {courseSubmittedCount}
              </div>
            )
            : null}
        </div>
      </NavLink>
    );
  }, [courseModifyRequestCount,
    courseSubmittedCount,
    currentPath,
    eventModifyRequestCount,
    eventSubmittedCount,
    newsModifyRequestCount,
    newsSubmittedCount,
    productModifyRequestCount,
    productSubmittedCount,
    profile?.role]);

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={() => { closeDrawer(); }}
      placement="left"
      maskMotion={{
        motionAppear: true,
        motionName: 'mask-motion',
      }}
      motion={{
        motionAppear: true,
        motionName: 'panel-motion-left',
      }}
      styles={{
        wrapper: {
          width: SIDE_BAR_WIDTH,
        },
      }}
    >
      <div className="h-full bg-white px-4 pb-4">
        <div className="mx-auto w-full max-w-md bg-transparent">
          <Link
            to={ROUTES.HOME}
            className="flex w-full items-center justify-start py-[12px]"
          >
            <Image src={Logo} alt="logo" width={150} />
          </Link>
          {currentPath
            && (shop ? SHOP_MENUS(shop.shopId) : MENUS)
              .filter((menu) => !('permissions' in menu)
                || (menu.permissions?.length
                && menu.permissions.some((permissions) => permissions === profile?.role)))
              .map((menu) => (
                <MenuDisplay key={menu.key} menu={menu} />
              ))}
        </div>
      </div>
    </Drawer>
  );
};
