import dayjs from 'dayjs';
import { z } from 'zod';

import { validatePermission } from 'app/helpers/CommerceItem';
import { ReviewStatusType } from 'app/models/CommerceItem';
import { FormPreviewItem, PublishStatusType } from 'app/models/Common';
import { DriveType, FolderType } from 'app/models/Drive';

import { COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA, COMMERCE_ITEM_PERMISSION_FORM_SCHEMA } from './CommerceItem';
import { PHONE_REGEX } from './Common';

export const NEWS_BASIC_INFO_FORM_SCHEMA = z
  .object({
    title: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填'),
    shopId: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    commerceCategory: z
      .number({
        required_error: '必選',
      })
      .nullable()
      .refine((val) => val && val > 0, { message: '必選' }),
    CommerceItemHashTags: z
      .array(
        z.object({
          commerceItemHashTagId: z
            .number().optional(),
          hashTag: z
            .string().min(1, '必填'),
        }),
      ),
    coverPhoto: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      )
      .min(1, '必填'),
    images: z
      .array(
        z.instanceof(File)
          .or(z.custom<FormPreviewItem>()),
        {
          required_error: '必填',
        },
      ),
    referenceCode: z
      .string(),
    contactPerson: z
      .string(),
    contactPhoneNumber: z
      .string()
      .regex(PHONE_REGEX, '請輸入正確的手機號碼')
      .or(z.literal('')),
    subtitle: z
      .string()
      .max(200, '超出字數上限'),
    description: z
      .string({
        required_error: '必填',
      })
      .min(1, '必填'),
    attachment: z
      .object({
        path: z
          .string(),
        folderType: z
          .custom<FolderType>(),
        driveType: z
          .custom<DriveType>(),
        hiddenRoot: z
          .string(),
      }).nullable(),
    surveyId: z
      .number()
      .nullable(),
    publishStatus: z
      .custom<PublishStatusType>(),
    publishedDate: z
      .date()
      .nullable(),
    publishEndDate: z
      .date()
      .nullable(),
  })
  .refine(
    ({ publishStatus, publishedDate }) => publishStatus === 'Published' || publishedDate,
    { message: '請選擇發佈時間', path: ['publishDate'] },
  )
  .refine(
    ({ publishStatus, publishedDate, publishEndDate }) => !publishEndDate
    || dayjs(publishStatus === 'Unpublished' ? publishedDate || undefined : undefined).isSameOrBefore(dayjs(publishEndDate)),
    { message: '結束發佈時間不能比開始發佈時間早', path: ['publishEndDate'] },
  );

export type NewsBasicInfoFormDataType = z.infer<typeof NEWS_BASIC_INFO_FORM_SCHEMA>;

export const INITIALIZED_NEWS_BASIC_INFO : NewsBasicInfoFormDataType = {
  title: '',
  CommerceItemHashTags: [],
  coverPhoto: [],
  images: [],
  subtitle: '',
  description: '',
  attachment: null,
  surveyId: null,
  publishStatus: 'Published',
  publishedDate: null,
  publishEndDate: null,
  shopId: null,
  commerceCategory: null,
  referenceCode: '',
  contactPerson: '',
  contactPhoneNumber: '',
};

export const NEWS_FORM_SCHEMA = z
  .object({
    reviewStatus: z
      .custom<ReviewStatusType>()
      .optional(),
    remarks: z
      .string(),
    basicInfo: NEWS_BASIC_INFO_FORM_SCHEMA,
    permission: COMMERCE_ITEM_PERMISSION_FORM_SCHEMA,

    pushNotification: COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA,
  })
  .superRefine(({
    reviewStatus, permission,
  }, ctx) => {
    if (reviewStatus !== '草稿') {
      validatePermission(permission, ctx);
    }
  });

export type NewsFormDataType = z.infer<typeof NEWS_FORM_SCHEMA>;
