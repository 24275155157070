import { ReactNode } from 'react';
import { AiTwotoneShop } from 'react-icons/ai';
import {
  PiCalendarDuotone,
  PiPanoramaDuotone,
  PiCreditCardDuotone,
  PiUserCircleDuotone,
  PiUsersThreeDuotone,
  PiInfoDuotone,
  PiAddressBookDuotone,
  PiTrophyDuotone,
  PiBellDuotone,
  PiCurrencyCircleDollarDuotone,
  PiWrenchDuotone,
  PiConfettiDuotone,
  PiBagDuotone,
  PiNewspaperDuotone,
  PiHashDuotone,
  PiScrollDuotone,
  PiDropboxLogoDuotone,
  PiFolderOpenDuotone,
  PiBuildingsDuotone,
  PiBooksDuotone,
  PiCoinsDuotone,
  PiCalendarStarDuotone,
  PiCalendarBlankDuotone,
  PiDotsThreeCircleVerticalDuotone,
} from 'react-icons/pi';

import { AdminRole, AdminRoleType } from 'app/models/Admin';

import { ROUTES } from '.';

export interface MenuItem {
  key: string,
  icon: ReactNode,
  title: string,
  permissions?: AdminRoleType[];
  hasDepartmentShop?: boolean;
  href: string | {
    pathname: string;
    query: { [key:string]: string }
  };
}

export interface MenuParentItem {
  key: string,
  title: string,
  icon: ReactNode,
  permissions: AdminRoleType[];
  children: (Omit<MenuItem, 'icon'> | MenuParentItem)[]
}

// remark:
// the key in children is not important, it is just the unique key for mapping.
// while the key in parent is for the active class in NavLink.
// the href in children must start with '/parent-key'.

export const MENUS: (MenuParentItem | MenuItem)[] = [
  {
    key: 'events',
    title: '活動',
    icon: <PiCalendarDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主, AdminRole.店員],
    href: ROUTES.EVENTS_LIST,
  },
  {
    key: 'news',
    title: '資訊',
    icon: <PiNewspaperDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主, AdminRole.店員],
    href: ROUTES.NEWS_LIST,
  },
  {
    key: 'product',
    title: '貨品',
    icon: <PiDropboxLogoDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主, AdminRole.店員],
    href: ROUTES.PRODUCT_LIST,
  },
  {
    key: 'course',
    title: '課程',
    icon: <PiBooksDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主, AdminRole.店員],
    href: ROUTES.COURSE_LIST,
  },
  // notification
  {
    key: 'notifications',
    title: '推送訊息',
    icon: <PiBellDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員],
    href: ROUTES.NOTIFICATIONS_LIST,
  },
  // shop
  {
    key: 'shop',
    title: '商店',
    icon: <AiTwotoneShop className="size-5" />,
    permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主],
    children: [
      {
        key: 'shop-list',
        title: '商店列表',
        href: ROUTES.SHOP_LIST,
      },
      {
        key: 'shop-category-list',
        title: '商店類別',
        permissions: [AdminRole.超級管理員],
        href: ROUTES.SHOP_CATEGORY_SORT,
      },
      {
        key: 'shop-sort',
        title: '商店排序',
        permissions: [AdminRole.超級管理員],
        href: ROUTES.SHOP_SORT,
      },
      {
        key: 'shop-featured-list',
        title: '特色商店排序',
        permissions: [AdminRole.超級管理員],
        href: ROUTES.SHOP_FEATURED_SORT,
      },
    ],
  },
  // survey
  {
    key: 'survey',
    title: '問卷',
    icon: <PiScrollDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主, AdminRole.店員],
    children: [
      {
        key: 'survey-list',
        title: '問卷',
        href: ROUTES.SURVEY_LIST,
      },
      {
        key: 'survey-response-list',
        title: '問卷反饋',
        href: ROUTES.SURVEY_RESPONSE_LIST(),
      },
    ],
  },
  // transaction
  {
    key: 'transactions',
    title: '會員交易紀錄',
    icon: <PiCurrencyCircleDollarDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員],
    href: ROUTES.TRANSACTION_LIST(),
  },
  {
    key: 'statements',
    title: '會員帳單',
    icon: <PiCreditCardDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員],
    href: ROUTES.STATEMENTS_LIST(),
  },
  {
    key: 'advanceManage',
    title: '進階管理',
    icon: <PiDotsThreeCircleVerticalDuotone className="size-5" />,
    permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主, AdminRole.店員],
    children: [
      {
        key: 'company-activity',
        title: '公司活動',
        icon: <PiCalendarStarDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        href: ROUTES.COMPANY_ACTIVITY_LIST,
      },

      // info
      {
        key: 'info',
        title: '業界資訊',
        icon: <PiInfoDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        children: [
          {
            key: 'list-info-list',
            title: '業界資訊',
            href: ROUTES.INFO_LIST,
          },
          {
            key: 'list-info-sort',
            title: '業界資訊排序',
            href: ROUTES.INFO_LIST_SORT,
          },
        ],
      },
      // contact
      {
        key: 'contact',
        title: '聯絡資料',
        icon: <PiAddressBookDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        children: [
          {
            key: 'contact-list',
            title: '聯絡資料',
            href: ROUTES.CONTACT_LIST,
          },
          {
            key: 'contact-sort',
            title: '聯絡資料排序',
            href: ROUTES.CONTACT_CATEGORY_SORT,
          },
        ],
      },
      {
        key: 'banners',
        title: '橫額',
        icon: <PiPanoramaDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        children: [
          {
            key: 'banners-list',
            title: '橫額',
            href: ROUTES.BANNER_LIST,
          },
          {
            key: 'banner-sort',
            title: '橫額排序',
            href: ROUTES.BANNER_LIST_SORT,
          },
        ],
      },
      {
        key: 'commerceBanner',
        title: '商店橫額',
        icon: <PiPanoramaDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        href: ROUTES.COMMERCE_BANNER_SORT,
      },
      {
        key: 'popUpBanner',
        title: '彈出海報',
        icon: <PiConfettiDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        href: ROUTES.POP_UP_BANNER_FORM,
      },
      // performance
      {
        key: 'performance',
        title: '龍虎榜',
        icon: <PiTrophyDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        children: [
          {
            key: 'performance-list',
            title: '會員表現',
            href: ROUTES.PERFORMANCE_LIST(),
          },
          {
            key: 'performance-ranking-list',
            title: '會員龍虎榜',
            href: ROUTES.PERFORMANCE_RANKING_LIST,
          },
        ],
      },
      // maintenance
      {
        key: 'maintenance',
        title: '維護模式',
        icon: <PiWrenchDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        href: ROUTES.MAINTENANCE,
      },
      // commerce item
      {
        key: 'commerceItem',
        title: '商品',
        icon: <PiBagDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        children: [
          {
            key: 'commerceItem-Category-List',
            title: '商品類別',
            href: ROUTES.COMMERCE_ITEM_CATEGORY_SORT,
          },
        ],
      },
      {
        key: 'department',
        title: '部門',
        icon: <PiBuildingsDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        href: ROUTES.DEPARTMENT_SORT,
      },
      {
        key: 'hashTags',
        title: '標籤',
        icon: <PiHashDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        children: [
          {
            key: 'hashTags-featured-sort',
            title: '特色標籤',
            href: ROUTES.FEATURED_HASH_TAG_SORT,
          },
        ],
      },
      {
        key: 'users',
        title: '會員',
        icon: <PiUserCircleDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        children: [
          {
            key: 'user-list',
            title: '會員列表',
            href: ROUTES.USERS,
          },
          {
            key: 'user-tags-list',
            title: '會員Tags',
            href: ROUTES.USER_TAGS_LIST,
          },
        ],
      },
      {
        key: 'system-config',
        title: '積分設定',
        icon: <PiCoinsDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        href: ROUTES.SYSTEM_CONFIG,
      },
      {
        key: 'instantEvent',
        title: '即時活動',
        icon: <PiCalendarBlankDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員],
        href: ROUTES.INSTANT_EVENT_LIST,
      },
      {
        key: 'drive',
        title: '資料庫',
        icon: <PiFolderOpenDuotone className="size-5" />,
        hasDepartmentShop: true,
        permissions: [AdminRole.管理員, AdminRole.店主, AdminRole.店員],
        href: ROUTES.DRIVE,
      },
      {
        key: 'admins',
        title: '管理員',
        icon: <PiUsersThreeDuotone className="size-5" />,
        permissions: [AdminRole.超級管理員, AdminRole.管理員, AdminRole.店主],
        href: ROUTES.ADMINS,
      },
    ],
  },
];
