import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { SIDE_BAR_WIDTH } from 'app/constants';
import { MenuItem, MenuParentItem, MENUS } from 'app/constants/Menu';
import { SHOP_MENUS } from 'app/constants/ShopMenu';
import {
  useAuthStore, useCommerceItemStore, useLayoutStore, useShopStore,
} from 'app/stores';
import cn from 'app/utils/ClassName';

import { SideBarDisclosure } from './SideBarDisclosure';

const NAV_FONT_STYLE_CLASS = 'flex w-full justify-between bg-bg-transparent py-3 text-left text-[14px] font-normal transition-colors duration-300';
const NAV_HOVER_FONT_STYLE_CLASS = 'hover:text-brand-primary focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75';

export const SideBar = ({ currentPath }: { currentPath: string }) => {
  const { shop } = useShopStore();
  const { isSideBarOpen } = useLayoutStore();
  const { profile } = useAuthStore();
  const {
    newsSubmittedCount,
    productSubmittedCount,
    eventSubmittedCount,
    courseSubmittedCount,
    newsModifyRequestCount,
    productModifyRequestCount,
    eventModifyRequestCount,
    courseModifyRequestCount,
  } = useCommerceItemStore();

  // eslint-disable-next-line react/no-unused-prop-types
  const MenuDisplay = useCallback(({ menu, parentId }: { menu: MenuParentItem | MenuItem | Omit<MenuItem, 'icon'>, parentId?: string }) => {
    if ('children' in menu) {
      return (
        <SideBarDisclosure
          key={menu.key}
          isSideBarOpen={isSideBarOpen}
          menu={menu}
          defaultOpen={currentPath === menu.key || menu.children.some((child) => currentPath === child.key)}
          isOpen={currentPath === menu.key || menu.children.some((child) => currentPath === child.key)}
          parentId={parentId}
        >
          {menu.children
            .filter((item) => !('permissions' in item)
            || (item.permissions?.length
            && item.permissions.some((permissions) => permissions === profile?.role))).map((child) => (
              <MenuDisplay key={child.key} menu={child} parentId={menu.key} />
            ))}
        </SideBarDisclosure>
      );
    }

    if ((menu.permissions?.length && !menu.permissions.some((permissions) => permissions === profile?.role))
      || (menu.hasDepartmentShop && profile?.role === '管理員' && !profile?.Department?.shopId)
    ) {
      return null;
    }

    return (
      <NavLink
        key={menu.key}
        to={menu.href}
        className={({ isActive }) => cn(
          NAV_FONT_STYLE_CLASS,
          NAV_HOVER_FONT_STYLE_CLASS,
          'flex items-center justify-start gap-[8px]',
          `${
            isActive
              ? 'text-brand-primary'
              : 'text-text-secondary'
          }`,
        )}
      >
        {'icon' in menu ? menu.icon : null}
        <div className="flex-1">{menu.title}</div>
        <div className="flex items-center gap-[8px]">
          {menu.key === 'news' && newsModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {newsModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'product' && productModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {productModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'events' && eventModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {eventModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'course' && courseModifyRequestCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-primary/80 text-[12px] text-white">
                {courseModifyRequestCount}
              </div>
            )
            : null}
          {menu.key === 'news' && newsSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {newsSubmittedCount}
              </div>
            )
            : null}
          {menu.key === 'product' && productSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {productSubmittedCount}
              </div>
            )
            : null}
          {menu.key === 'events' && eventSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {eventSubmittedCount}
              </div>
            )
            : null}
          {menu.key === 'course' && courseSubmittedCount
            ? (
              <div className="flex size-[20px] items-center justify-center rounded-full bg-brand-attention/80 text-[12px] text-white">
                {courseSubmittedCount}
              </div>
            )
            : null}
        </div>
      </NavLink>
    );
  }, [courseModifyRequestCount,
    courseSubmittedCount,
    currentPath,
    eventModifyRequestCount,
    eventSubmittedCount,
    isSideBarOpen,
    newsModifyRequestCount,
    newsSubmittedCount,
    productModifyRequestCount,
    productSubmittedCount,
    profile]);

  return (
    <div
      className={cn('h-full bg-white overflow-y-auto overflow-x-hidden', isSideBarOpen ? 'hidden non-mobile:block' : 'hidden')}
      style={{ minWidth: SIDE_BAR_WIDTH, width: SIDE_BAR_WIDTH }}
    >
      <div
        className="px-4 pb-4"
        style={{
          width: SIDE_BAR_WIDTH,
        }}
      >
        <div className="mx-auto w-full max-w-md bg-transparent">
          {currentPath
            && (shop ? SHOP_MENUS(shop.shopId) : MENUS)
              .filter((menu) => !('permissions' in menu)
               || (menu.permissions?.length
                && menu.permissions.some((permissions) => permissions === profile?.role)))
              .map((menu) => (
                <MenuDisplay menu={menu} key={`menu-${menu.key}`} />
              ))}
        </div>
      </div>
    </div>
  );
};
